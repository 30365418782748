
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import HistorySection from '@/components/sections/HistorySection.vue'

export default defineComponent({
  name: 'History',
  components: {
    Main,
    HistorySection
  }
})
