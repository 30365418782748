<template>
  <div class="history-section" v-if="translateData">
    <div class="history-section__title">{{ this.translateData.transaction_hitory }}</div>
    <div class="history-section__nav-menu">
      <div class="history-section__nav-item" @click="goToInTab" :class="{ accrualType: this.accrualTypeIn }">{{
        this.translateData.history_deposits }}</div>
      <div class="history-section__nav-item" @click="goToOutTab" :class="{ accrualType: this.accrualTypeOut }">{{
        this.translateData.history_withdrawals }}</div>
      <div class="history-section__nav-item" @click="goToReferralTab" :class="{ accrualType: this.accrualTypeReferral }">
        {{ this.translateData.history_accrued }}</div>
    </div>
    <div class="history-section__table">
      <div class="history-section__row">
        <div class="history-section__col">
          {{ this.translateData.date }}
        </div>
        <div class="history-section__col">{{ this.translateData.amount }}</div>
        <div class="history-section__col">{{ this.translateData.via }}</div>
        <div class="history-section__col">
          {{ this.translateData.status }}
        </div>
      </div>
      <div class="history-section__row history-section__row-body" v-for="data in this.historyData" :key="data.id">
        <div class="history-section__col">
          {{ data?.date }}
        </div>
        <div class="history-section__col">{{ data?.amount }}</div>
        <div class="history-section__col">{{ data?.payment_type?.title }}</div>
        <div class="history-section__col">
          <div class="history-section__col-icon">
            <template v-if="data?.status?.value == 'processed'">
              <CheckSmall />
            </template>
          </div>
          {{ data?.status?.title }}
        </div>
      </div>
      <div class="history-section__row-empty" v-if="this.historyData.length === 0">
        {{ this.translateData.history_empty }}
      </div>
    </div>
    <div v-if="historyMeta.last_page != undefined" class="history-section__pagination">
      <div class="counter">
        {{ historyMeta.current_page }} из {{ historyMeta.last_page }}
      </div>
      <div class="pagination">
        <div @click="handlePage(page - 1)" v-if="historyMeta.current_page != 1" class="nav prev">
          <ArrowSmall />
        </div>
        <div @click="handlePage(page + 1)" v-if="historyMeta.current_page != historyMeta.last_page" class="nav next">
          <ArrowSmall />
        </div>
      </div>
    </div>
    <RefLink />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import CheckSmall from '@/assets/svg/check-small.svg?inline'
import ArrowSmall from '@/assets/svg/arrow-small.svg?inline'

import RefLink from '@/components/molecules/RefLink.vue'

export default defineComponent({
  name: 'HistorySection',
  data () {
    return {
      historyData: {},
      translateData: {},
      accrualTypeIn: true,
      accrualTypeOut: false,
      accrualTypeReferral: false,
      page: 1,
      historyMeta: {}
    }
  },
  components: {
    CheckSmall,
    RefLink,
    ArrowSmall
  },
  mounted () {
    this.translate()
    this.goToInTab()
    this.getReferrals()
  },
  methods: {
    translate (data) {
      data = []
      data.push('transaction_hitory', 'made_by_ut', 'history_deposits', 'history_withdrawals', 'history_accrued', 'date', 'amount', 'via', 'status', 'history_empty')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    getHistory (type) {
      let url = `/${type}`
      url += `?page=${this.page}`
      this.$store.dispatch('getPayments/getHistory', url).then(
        (response) => {
          this.historyData = response.data
          // this.historyData = [{
          //   date: '31.10.2023',
          //   amount: '11'
          // },
          // {
          //   date: '31.10.2023',
          //   amount: '11'
          // }]
          this.historyMeta = response.meta
        },
        (error) => {
          console.log(error)
        }
      )
    },
    getReferrals () {
      this.$store.dispatch('getPayments/getReferrals').then(
        (response) => {
          console.log(response)
        },
        (error) => {
          console.log(error)
        }
      )
    },
    handlePage (page) {
      this.page = page
      const type = this.accrualTypeIn ? 'in' : this.accrualTypeOut ? 'out' : 'referral'
      this.getHistory(type)
    },
    goToInTab () {
      this.page = 1
      this.getHistory('in')
      this.accrualTypeIn = true
      this.accrualTypeOut = false
      this.accrualTypeReferral = false
    },
    goToOutTab () {
      this.page = 1
      this.getHistory('out')
      this.accrualTypeIn = false
      this.accrualTypeOut = true
      this.accrualTypeReferral = false
    },
    goToReferralTab () {
      this.page = 1
      this.getHistory('referral')
      this.accrualTypeIn = false
      this.accrualTypeOut = false
      this.accrualTypeReferral = true
    }
  }
})
</script>

<style lang='scss' scoped>
.history-section {
  padding: 80px 5% 0 45px;

  &__title {
    font-size: 24px;
    padding-bottom: 20px;
    text-align: left;
  }

  &__nav-menu {
    display: flex;
  }

  &__nav-item {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    padding-right: 50px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: #7CAF22;
      transition: all .2s ease-out;
    }
  }

  &__table {
    border-radius: 10px;
    margin-top: 15px;
    border: 1px solid rgba(178, 180, 185, 0.78);
    width: 62vw;
  }

  &__row {
    display: flex;
    padding: 0 40px;
    fill: #F3F4F5;
    background: #F3F4F5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid rgba(178, 180, 185, 0.78);

    &:last-child {
      border: none;
    }
  }

  &__row-body {
    background: transparent;
  }

  &__row-empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  &__col {
    display: flex;
    width: 20%;
    font-size: 14px;
    padding: 10px 0;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__col-icon {
    padding-right: 5px;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0 75px 0;

    .pagination {
      display: flex;
      padding-left: 20px;

      .nav {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin-left: 10px;
        background: #15181B;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          border-radius: 10px;
        }

        &.next {
          transform: rotate(180deg);
        }
      }
    }
  }

  .accrualType {
    color: #7CAF22;
  }
}

@media (max-width: $break-xlg) {
  .history-section {
    padding: 30px 20px 200px;

    &__row {
      padding: 0 20px;
    }
  }
}

@media (max-width: $break-sm) {
  .history-section {
    padding: 80px 10px;
    width: 100%;
    background: linear-gradient(90deg, #FFF 0.21%, #F4F8E9 99.63%);
    height: 330px;

    &__title {
      font-size: 16px;
      padding-bottom: 15px;
    }

    &__nav-menu {}

    &__nav-item {
      width: 100%;
      margin-bottom: 5px;
      font-size: 14px;
      padding-right: 10px;
    }

    &__row {
      padding: 0 5px;
    }

    &__row-empty {
      font-size: 16px;
    }

    &__col {
      font-size: 13px;
      flex-wrap: wrap;
      padding: 20px 5px;
    }

    &__table {
      width: 100%;
    }
  }
}</style>
